.navbar {
    color: #333;
    padding: 10px 20px;
    font-size: 0.9em;
    
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(5px);
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar-logo {
    font-size: 1.5em;
    color: #333;
    text-decoration: none;
  }
  
  .navbar-menu {
    list-style: none;
    display: flex;
    margin: 0
  }
  
  .navbar-menu li {
    margin: 0 15px;
  }
  
  .navbar-menu a {
    color: #333;
    text-decoration: none;
    font-size: 0.8em;
  }