@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.App {
  text-align: center;
  font-family: "Mulish", sans-serif;
  min-height: 100vh;
  color: #333;

  /* https://www.svgbackgrounds.com/set/free-svg-backgrounds-and-patterns/ */
  background-color: #6BC4A8;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%2376d5c5' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%2384e2e1' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%2394e2ee' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23a7e0f6' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23BDE1FC' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23cce9f9' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23d9eff7' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23e4f3f6' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23eef7f7' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23F7FAFA' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

h2 {
  font-size: 16px;
  font-family: "Mulish", sans-serif;
}

p {
  font-size: 12px;
  font-family: "Mulish", sans-serif;
}

.body {
  flex: 1;
  /* background-color: #f9f9f9; */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: #333;
  padding: 50px;

}

form {
  background: #e9e9e9;
  padding: 20px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.file-upload {
  margin: 10px;
  margin-bottom: 20px;
}

.custom-file-upload {
  background-color: #fff;
  color: #333;
  border: 2px solid #333;
  padding: 10px 20px;
  cursor: pointer;
  display: inline-block;
  border-radius: 5px;
}

input[type="file"] {
  display: none;
}

button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

button:disabled {
  background-color: #888;
}

.error {
  color: red;
  margin-top: 10px;
}

.seoblock {
  padding: 10px 20px 20px 20px;
  margin-top: 80px;
  max-width: 800px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px;

  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.result {
  align-items: center;
  position: relative;
  /* background-color: #ffffffd7; */
  padding: 20px;
  margin: 5px;
  margin-top: 20px;
  border-radius: 5px;

}

.resulttoptext {
  text-align: left;
  margin: 20px;
  min-width: 300px;

  padding: 20px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.backbutton {
  margin: 50px 0 0 0 ;
}

.resulttop {
  display: flex;
  flex-direction: row;
  min-height: 200px;
  max-width: 600px;
  margin: auto;
  padding: 10px;
  align-items: center;
  justify-content: center;
  justify-content: space-evenly;
}

.resultimg {
  border-radius: 5px;

  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

@media screen and (max-width: 800px) {
  .resulttop { 
    flex-direction: column;
  }
}

.resultbody {
  display: flex;
  flex-direction: row;
  min-height: 200px;
  max-width: 900px;
  margin: auto;
  padding: 10px;
  justify-content: center;
  justify-content: space-evenly;

  background: #ffffff;
  padding: 20px;
  /* background: rgba(255, 255, 255, 0.3); */
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 800px) {
  .resultbody { 
    flex-direction: column;
  }
}

.resultbody2 {
  justify-content: center;
  justify-content: space-evenly;

  border-radius: 10px;
  flex: 1;
  padding: 0 30px 20px 30px;
}

.pos {
  border: 2px solid #6dbf6f;
}

.neg {
  border: 2px solid #bf736d;
}

.resultbody2 li {
  text-align: left;
  margin-bottom: 10px;
}

.blur {
  filter: blur(3px)
}

.unlockbutton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Position it above the parent */
  z-index: 10;
}

.checkoutbody {
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.checkoutcol {
  width: 50%;
  padding: 0 20px 0 20px;
}

.dashed {
  border-right: 5px dotted lightgrey;
}


@media screen and (max-width: 800px) {
  .checkoutbody { 
    flex-direction: column;
  }
  .checkoutcol {
    width: 100%;
    padding: 10px;
  }
  .dashed {
    border-right: 0px dotted lightgrey;
  }
  
}